import { useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'models'
import { ApiErrorKind } from 'services/api'
import { useToast } from 'hooks/use-toast.hook'

export default function useResetPassword() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showToast } = useToast()

  const {
    authStore: { resetPassword, email, token, code },
  } = useStores()

  const [error, setError] = useState(null)

  const resetPasswordResolver = yupResolver(
    yup.object().shape({
      password: yup.string().required(t('Please enter your password')).min(8, 'Minimum 8 chars'),
      confirmPassword: yup
        .string()
        .required(t('Please enter your password'))
        .oneOf([yup.ref('password'), null], "Password doesn't match"),
    })
  )

  const navigateToLogin = () => {
    navigate('/account/login', { replace: true })
  }

  const backTo = () => {
    navigate(-1)

    sessionStorage.clear()
  }

  const doResetPassword = async (data: Record<string, any>) => {
    try {
      setError(null)
      const changePassword = await resetPassword({ email, token, code, ...data })

      if (changePassword) {
        navigateToLogin()

        sessionStorage.clear()
        showToast({ variant: 'success', body: t('Reset Password is successfully') })
      }
    } catch (error: any) {
      if (
        error.kind === ApiErrorKind.UNPROCESSABLE ||
        error.kind === ApiErrorKind.NOT_FOUND ||
        error.kind === ApiErrorKind.BAD_REQUEST
      ) {
        setError(t('Please filled all form'))
      }
    }
  }

  return { resetPasswordResolver, doResetPassword, error, backTo }
}
