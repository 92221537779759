import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { paymentPath } from 'config/paths'
import { welcomeOrganizerPath } from 'config/paths/auth-path'
import { LoadComponent } from 'routes/load-component'
import { eventDetailPath } from 'config/paths/event-path'
import { calendarDetailPath, calendarPath } from 'config/paths/calendar-path'
import { tutorialPath } from 'config/paths/tutorial-path'

const calendar = lazy(() => import('pages/creator/calendar'))

const payment = lazy(() => import('pages/creator/payment/payment'))

const event = lazy(() => import('pages/creator/event'))

const formOrganizer = lazy(() => import('pages/welcome/type-organizer/type-organizer'))
const welcomeOrganizer = lazy(() => import('pages/welcome/welcome-organizer/welcome-organizer'))

const organizerReport = lazy(() => import('pages/creator/organizer-report/index'))

const tutorials = lazy(() => import('pages/creator/tutorials/index'))

export const calendarRoutes = (): RouteObject[] => [
  {
    path: calendarPath(),
    element: <LoadComponent component={calendar} />,
  },
  {
    path: calendarDetailPath(':id'),
    element: <LoadComponent component={calendar} />,
  },
]

export const paymentRoutes = (): RouteObject[] => [
  {
    path: paymentPath(),
    element: <LoadComponent component={payment} />,
  },
]

export const eventRoutes = (): RouteObject[] => [
  {
    path: eventDetailPath(':id'),
    element: <LoadComponent component={event} />,
  },
]

export const welcomeOrganizerRoutes = (): RouteObject[] => [
  {
    path: welcomeOrganizerPath(),
    element: <LoadComponent component={welcomeOrganizer} />,
  },
  {
    path: welcomeOrganizerPath(':id'),
    element: <LoadComponent component={formOrganizer} />,
  },
]

export const organizerRoutes = (): RouteObject[] => [
  {
    path: '/organizer-report',
    element: <LoadComponent component={organizerReport} />,
  },
]

export const tutorialsRoutes = (): RouteObject[] => [
  {
    path: tutorialPath(),
    element: <LoadComponent component={tutorials} />,
  },
  {
    path: tutorialPath(':id'),
    element: <LoadComponent component={tutorials} />,
  },
]
