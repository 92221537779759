import { Payload } from 'services/api'

import { SelectedChallengeType } from 'models/selected-challenge'
import { TicketType, UploadingImageType } from 'models/challenge'

export const buildChallengePayload = (payload: Payload & SelectedChallengeType) => {
  const {
    title,
    label,
    category,
    startDate,
    endDate,
    location,
    longitude,
    latitude,
    overview,
    description,
    media,
    videoLink,
    tickets,
    tasks,
    safetyTipsAndGuidelines,
    additionalInformation,
    thumbnails,
    status,
  } = payload

  return {
    title,
    description,
    category,
    overview,
    participantLimit: tickets
      .map((ticket: TicketType) => ticket.max_quantity)
      .reduce((a: number, b: number) => a + b, 0),
    price: tickets[0].price,
    eventLabel: label,
    location,
    longitude,
    latitude,
    safetyGuide: safetyTipsAndGuidelines,
    videoLink,
    startDate,
    endDate,
    additionalInformation,
    tasks: tasks.map((task) => ({
      ...task,
      category,
    })),
    tickets: tickets.map((ticket) => {
      const merchandises = ticket.merchandises as UploadingImageType[]

      return {
        ...ticket,
        itemIncude: ticket.includes,
        maxQuantity: ticket.max_quantity,
        merchandises: merchandises?.map((merchandise) => ({
          fileName: merchandise?.image?.name,
          base64: merchandise?.image?.base64Data,
        })),
      }
    }),
    media: {
      fileName: (media as UploadingImageType[])[0]?.name,
      base64: (media as UploadingImageType[])[0]?.image?.base64Data,
    },
    medias: (thumbnails as UploadingImageType[]).map((thumbnail) => ({
      fileName: thumbnail.name,
      base64: thumbnail.image.base64Data,
    })),
    status,
  }
}
