import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface PaymentStatusProps {
  status: string | any
  onClick?: () => void
}

export const PaymentStatus = ({ status }: PaymentStatusProps) => {
  const { t } = useTranslation()

  switch (status) {
    case 'pending':
      return (
        <div className='payment-status bg-primary-yellow'>
          <i className='icon-clock me-1' />
          <span className='text-capitalize'>{t('Pending')}</span>
        </div>
      )
    case 'completed':
      return (
        <p className='payment-status bg-primary'>
          <i className='icon-check me-1' />
          <span className='text-capitalize'>{t('Completed')}</span>
        </p>
      )
    default:
      return (
        <p className='payment-status bg-neutral-2'>
          <i className='icon-dot me-1' />
          <span className='text-capitalize'>{t('No Earning')}</span>
        </p>
      )
  }
}

export const PaymentStatusTabel = ({ status }: PaymentStatusProps) => {
  const { t } = useTranslation()

  switch (status) {
    case 'succeeded':
      return (
        <p className='payment-status bg-primary'>
          <i className='icon-check me-1' />
          <span className='text-capitalize'>{t('Paid')}</span>
        </p>
      )
    default:
      return (
        <div className='payment-status bg-primary-yellow'>
          <i className='icon-clock me-1' />
          <span className='text-capitalize'>{t('Pending')}</span>
        </div>
      )
  }
}

export const PaymentStatusTitle: FC<PaymentStatusProps> = ({ status }) => {
  const { t } = useTranslation()

  const statusTitle = useMemo(() => {
    switch (status) {
      case 'succeeded':
        return <p className='text-600 text-primary-green text-capitalize'>{t('Paid')}</p>
      case 'overdue':
        return <p className='text-600 text-danger text-capitalize'>{t('Overdue')}</p>
      case 'pending':
        return <p className='text-600 text-primary-yellow text-capitalize'>{t('Pending')}</p>
      default:
        return <p className='text-600 text-secondary text-capitalize'>{t('Not Eligible')}</p>
    }
  }, [status])

  return statusTitle
}

export const PaymentStatusApproval: FC<PaymentStatusProps> = ({ status, onClick }) => {
  const { t } = useTranslation()

  const statusElement = useMemo(() => {
    switch (status) {
      case 'paid':
        return (
          <div className='status-card bg-secondary rounded d-flex justify-content-center'>
            <p className='status-title m-0 fw-bold text-secondary'>{t('Paid')}</p>
          </div>
        )
      case 'notEligible':
        return (
          <div className='status-card bg-secondary rounded d-flex justify-content-center'>
            <p className='status-title m-0 fw-bold text-secondary'>{t('NE')}</p>
          </div>
        )
      default:
        return (
          <div
            className='status-card bg-secondary-green rounded d-flex justify-content-center clickable'
            onClick={() => onClick && onClick()}
          >
            <p className='status-title m-0 fw-bold text-primary-green'>{t('Pay')}</p>
          </div>
        )
    }
  }, [status])

  return statusElement
}
