import { Instance, SnapshotOut, applySnapshot, flow, types } from 'mobx-state-tree'

import { ApiSuccessResult, challengeApi, profileApi } from 'services/api'

import { ChallengeAnalyticsModel } from './challenge'
import { OrganizerReportModel, OrganizerReportOverviewModel } from './report'

export const AnalyticStoreModel = types
  .model('AnalyticStore')
  .props({
    analytics: types.optional(ChallengeAnalyticsModel, {}),
    organizerOverview: types.optional(OrganizerReportOverviewModel, {}),
    organizerList: types.array(OrganizerReportModel),
    totalPage: types.optional(types.number, 1),
  })
  .actions((self) => ({
    getChallengesAnalytic: flow(function* () {
      const result: ApiSuccessResult = yield challengeApi.getChallengesAnalytics()

      if (result.ok) applySnapshot(self.analytics, result.data.data)

      return result.ok
    }),

    getOrganizerReportOverview: flow(function* () {
      const result: ApiSuccessResult = yield profileApi.getOrganizerReportOverview()

      if (result.ok) applySnapshot(self.organizerOverview, result.data.data)

      return result.ok
    }),

    getOrganizerReportList: flow(function* () {
      const result: ApiSuccessResult = yield profileApi.getOrganizerReportList()

      if (result.ok) {
        applySnapshot(self.organizerList, result.data?.data?.datas)
        self.totalPage = result.data.data?.totalPages
      }

      return result.ok
    }),
  }))

export type AnalyticStore = Instance<typeof AnalyticStoreModel>
export type AnalyticStoreSnapshot = SnapshotOut<typeof AnalyticStoreModel>
