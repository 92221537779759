import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { dateIcon } from 'assets/icon'

import { TimePickerProps } from './timePicker.types'

export const ControlledTimePicker = (props: TimePickerProps) => {
  const { control } = useFormContext()

  const {
    inputClass,
    containerClass,
    label,
    name,
    showTimeSelect,
    timeFormat,
    timeCaption,
    dateFormat,
    showTimeSelectOnly,
    placeholder,
    required,
    showIcon,
    minDate,
    disabled,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const nextYear = useMemo(() => new Date().getFullYear() + 1, [])

  return (
    <Form.Group className={containerClass}>
      <div className='d-flex gap-1'>
        {label && <Form.Label>{label}</Form.Label>}
        {required && <span className='text-primary'>*</span>}
      </div>
      <Controller
        control={control}
        defaultValue={new Date()}
        name={name}
        render={({ field: { onChange: onFormChange, value }, fieldState: { error, invalid } }) => (
          <>
            <div className='custom-date'>
              <DatePicker
                open={isOpen}
                onInputClick={handleOpen}
                onClickOutside={handleClose}
                wrapperClassName={classNames({ 'is-invalid': invalid })}
                className={classNames('form-control', { 'is-invalid': invalid }, inputClass)}
                selected={value}
                onChange={(date) => {
                  onFormChange(date)
                  handleClose()
                }}
                showTimeSelect={showTimeSelect}
                timeFormat={timeFormat || 'hh:mm a'}
                timeCaption={timeCaption}
                dateFormat={dateFormat || 'MM/dd/yyyy'}
                minDate={minDate}
                showTimeSelectOnly={showTimeSelectOnly}
                placeholderText={placeholder}
                disabled={disabled}
                onChangeRaw={(e) => {
                  const date = new Date(e.target.value)
                  if (!isNaN(date.getTime())) {
                    onFormChange(date)
                  }
                }}
                renderCustomHeader={({ date, changeYear, changeMonth }) => (
                  <div className='datepicker-header'>
                    <select
                      value={date.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(Number(value))}
                      className='datepicker-select'
                    >
                      {Array.from({ length: 100 }, (_, i) => {
                        const year = new Date().getFullYear() + 1 - i
                        return year <= nextYear ? (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ) : null
                      })}
                    </select>
                    <select
                      value={date.getMonth()}
                      onChange={({ target: { value } }) => changeMonth(Number(value))}
                      className='datepicker-select'
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={i}>
                          {new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
                            new Date(0, i)
                          )}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              />
              {showIcon && (
                <div className='custom-date-icon' onClick={handleOpen}>
                  <img src={dateIcon} height={20} alt='Date Icon' />
                </div>
              )}
            </div>
            {error && (
              <Form.Control.Feedback type='invalid' className='fw-semibold d-block'>
                {error.message}
              </Form.Control.Feedback>
            )}
          </>
        )}
      />
    </Form.Group>
  )
}
