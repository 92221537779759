import React, { FunctionComponent } from 'react'
import { Alert, Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { FormInput, VerticalForm } from 'components'

import useRegister from './register.hook'
import { Link } from 'react-router-dom'
import { logoBannerWhite } from 'assets/images'

const Register: FunctionComponent = observer(() => {
  const { t } = useTranslation()

  const {
    registerResolver,
    doRegister,
    error,
    backTo,
    setIsAble,
    isAble,
    isShowModal,
    setIsShowModal,
    generalInfo,
  } = useRegister()

  return (
    <>
      <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
        <div className='container'>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5} xxl={4}>
              <Card>
                <Card.Header className='py-3 text-center bg-black'>
                  <Link to='/'>
                    <span>
                      <img src={logoBannerWhite} alt='' height='24' />
                    </span>
                  </Link>
                </Card.Header>
                <Card.Body className='px-4 py-2'>
                  <div className='m-auto text-center'>
                    <h4 className='text-black mt-0'>{t('Register')}</h4>
                    <p className='text-neutral-4 fw-semibold'>
                      {t('Enter your information to create an account')}
                    </p>
                  </div>
                  {error && (
                    <Alert variant='danger' className='my-2'>
                      {error}
                    </Alert>
                  )}
                  <VerticalForm onSubmit={doRegister} resolver={registerResolver}>
                    <FormInput
                      label={t('Email')}
                      type='email'
                      name='email'
                      placeholder={t('Enter your email')}
                      containerClass={'mb-2'}
                    />
                    <FormInput
                      label={t('Password')}
                      type='password'
                      name='password'
                      placeholder={t('Enter your password')}
                      containerClass={'mb-2'}
                    />
                    <FormInput
                      label={t('Confirm password')}
                      type='password'
                      name='confirmPassword'
                      placeholder={t('Confrim your password')}
                      containerClass={'mb-3'}
                    />

                    <div className='d-flex justify-content-between gap-3 align-items-center mb-3'>
                      <input
                        type='checkbox'
                        name='acceptTerms'
                        id='acceptTerms'
                        onChange={(event) => setIsAble(event.target.checked)}
                        style={{ transform: 'scale(1.5)', width: '12px', height: '12px' }}
                      />
                      <div onClick={() => setIsShowModal(true)} className='clickable'>
                        {t(
                          'I agree to the Community Guidelines, User Agreement, and Commercial Terms'
                        )}
                      </div>
                    </div>

                    <div className='text-center'>
                      <Button
                        variant='primary'
                        type='submit'
                        className='rounded px-4 py-2 fw-600'
                        disabled={!isAble}
                      >
                        {t('Register')}
                      </Button>
                    </div>
                    <div className='text-center mt-3'>
                      <p>
                        {t('Already have an account ? ')}
                        <a className='text-primary-green clickable' onClick={backTo}>
                          {t('Login here')}
                        </a>
                      </p>
                    </div>
                  </VerticalForm>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={isShowModal} onHide={() => setIsShowModal(false)} centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{generalInfo?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{generalInfo?.description}</Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setIsShowModal(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})

export default Register
