import React from 'react'

import Login from 'pages/shared/login/login'
import Logout from 'pages/shared/logout/logout'
import Register from 'pages/shared/register/register'
import ForgotPassword from 'pages/shared/forgot-password/forgot-password'
import VerificationToken from 'pages/shared/verification-code/verification-code'
import ResetPassword from 'pages/shared/reset-password/reset-password'

import RedirectRoute from '../redirect-route'

const AuthRoutes = [
  {
    path: 'account',
    element: <RedirectRoute route='account' redirectTo='/account/login' />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'logout', element: <Logout /> },
      { path: 'register', element: <Register /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'verify-reset-password', element: <VerificationToken /> },
      { path: 'reset-password', element: <ResetPassword /> },
    ],
  },
]

export default AuthRoutes
