import { createPath } from './path-utils'
import { PathGenerator } from './path.types'

const ADMIN_PATH = 'admin'

export const adminReportingPath: PathGenerator = () => {
  return createPath(ADMIN_PATH, { suffix: 'reporting' })
}

export const adminFinancialPath: PathGenerator = () => {
  return createPath(ADMIN_PATH, { suffix: 'financial' })
}
