import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { SideMenuProps } from './index.types'
import TabMenus from './components/tab-menus'

export const SideMenu: FC<SideMenuProps> = (props) => {
  const {
    numberTab,
    tabList,
    label = 'Menu',
    description = 'Set up your event',
    changeTab,
    onChangeId,
    customTabItem,
  } = props

  const { t } = useTranslation()

  const handleMenuClick = (index: number, id: number) => {
    changeTab(index)
    onChangeId && onChangeId(id)
  }

  return (
    <div className='border rounded-2'>
      <div className='m-2'>
        <h4 className='text-black'>{t(label)}</h4>
        <p>{t(description)}</p>
        <TabMenus
          items={tabList}
          numberTab={numberTab}
          handleClick={handleMenuClick}
          customTabItem={customTabItem}
        />
      </div>
    </div>
  )
}
