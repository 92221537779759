import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { CurrencyType } from 'types/global.types'

export const PaymentForOrganizerModel = types.model('PaymentForOrganizer').props({
  id: types.maybe(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  paymentStatus: types.maybeNull(types.string),
  eventName: types.maybeNull(types.string),
  organizerName: types.maybeNull(types.string),
  emailAddress: types.maybeNull(types.string),
  tag: types.maybeNull(types.string),
  completionDate: types.maybeNull(types.string),
  payoutDate: types.maybeNull(types.string),
  actualPayoutDate: types.maybeNull(types.string),
  paymentReference: types.maybeNull(types.string),
})

export type PaymentForOrganizerInstance = Instance<typeof PaymentForOrganizerModel>
export type PaymentForOrganizer = Omit<PaymentForOrganizerInstance, symbol>
export type PaymentForOrganizerSnapshot = SnapshotOut<typeof PaymentForOrganizerModel>

export const RefundPaymentsModel = types.model('RefundPayments').props({
  id: types.maybe(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  paymentStatus: types.maybeNull(types.string),
  eventName: types.maybeNull(types.string),
  participantName: types.maybeNull(types.string),
  emailAddress: types.maybeNull(types.string),
  amount: types.maybeNull(types.number),
  currency: types.maybeNull(types.enumeration<CurrencyType>(['usd', 'eur', 'idr'])),
  actualPayoutDate: types.maybeNull(types.string),
})

export type RefundPaymentsInstance = Instance<typeof RefundPaymentsModel>
export type RefundPayments = Omit<RefundPaymentsInstance, symbol>
export type RefundPaymentsSnapshot = SnapshotOut<typeof RefundPaymentsModel>

export const PaymentOrganizerModel = types.model('PaymentOrganizerModel').props({
  id: types.maybe(types.number),
  settlementStatus: types.maybeNull(types.string),
  eventName: types.maybeNull(types.string),
  totalSales: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
  completionDate: types.maybeNull(types.Date),
  actualPayout: types.maybeNull(types.Date),
  paymentReference: types.maybeNull(types.string),
})

export type PaymentOrganizerInstance = Instance<typeof PaymentOrganizerModel>
export type PaymentOrganizer = Omit<PaymentOrganizerInstance, symbol>
export type PaymentOrganizerSnapshot = SnapshotOut<typeof PaymentOrganizerModel>
