import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { CurrencyType } from 'types/global.types'

export const OrganizerReportModel = types.model('OrganizerReport').props({
  id: types.maybe(types.number),
  title: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  totalParticipants: types.maybeNull(types.number),
  totalRevenue: types.maybeNull(types.number),
  currency: types.maybe(types.enumeration<CurrencyType>(['usd', 'eur', 'idr'])),
  genderDemographics: types.maybeNull(
    types.model({
      F: types.optional(types.number, 0),
      M: types.optional(types.number, 0),
      O: types.optional(types.number, 0),
    })
  ),
  countryDemographics: types.maybeNull(
    types.array(
      types.model({
        country_icon: types.maybeNull(types.string),
        country_name: types.maybeNull(types.string),
        country_short_name: types.maybeNull(types.string),
        percentage: types.maybeNull(types.number),
      })
    )
  ),
})

export type OrganizerReportInstance = Instance<typeof OrganizerReportModel>
export type OrganizerReport = Omit<OrganizerReportInstance, symbol>
export type OrganizerReportSnapshot = SnapshotOut<typeof OrganizerReportModel>

export const OrganizerReportOverviewModel = types.model('OrganizerReport').props({
  totalEvent: types.optional(types.number, 0),
  totalCombinedParticipant: types.optional(types.number, 0),
  challengeHighestParticipant: types.maybeNull(
    types.model({
      id: types.maybeNull(types.number),
      title: types.maybeNull(types.string),
    })
  ),
  averageJoinedParticipants: types.optional(types.number, 0),
  ageDemographics: types.maybeNull(
    types.array(
      types.model({
        ageGroup: types.maybeNull(types.string),
        totalUser: types.optional(types.number, 0),
      })
    )
  ),
  genderDemographics: types.maybeNull(
    types.array(
      types.model({
        gender: types.maybeNull(types.string),
        totalUsers: types.optional(types.number, 0),
      })
    )
  ),
})

export type OrganizerReportOverviewInstance = Instance<typeof OrganizerReportOverviewModel>
export type OrganizerReportOverview = Omit<OrganizerReportOverviewInstance, symbol>
export type OrganizerReportOverviewSnapshot = SnapshotOut<typeof OrganizerReportOverviewModel>
