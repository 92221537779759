import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { TableHeaderProps } from './table.types'
import { getFilterFromSearchParams } from './table.helper'
import { FormInput, FormProvider } from 'components'
import Icon from 'components/icon'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'utils/object'

export const TableHeader = (props: TableHeaderProps) => {
  const { filters, onFilter } = props

  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  const methods = useForm()

  const { setValue, register, reset } = methods

  useEffect(() => {
    const filters = getFilterFromSearchParams(searchParams)

    Object.entries(filters).forEach(([key, value]) => {
      setValue(key, value)
    })

    if (isEmpty(filters)) reset()
  }, [searchParams])

  return (
    <Card.Header className='p-0 table-card-header'>
      <FormProvider methods={methods} onSubmit={onFilter}>
        <Row className='gap-2 gap-xl-0 flex-column flex-xl-row'>
          <Col xl={8}>{filters}</Col>
          <Col xl={4}>
            <div className='table-search'>
              <FormInput
                type='text'
                name='search'
                placeholder={t('Type to search..')}
                register={register}
              />
              <button type='submit' className='search-icon'>
                <Icon icon='icon-search' size={24} />
              </button>
            </div>
          </Col>
        </Row>
      </FormProvider>
    </Card.Header>
  )
}
