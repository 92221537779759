import React from 'react'

import { welcomeOrganizerRoutes } from 'pages/creator/routes'
import ChangeEmailVerif from 'pages/creator/verify/change-email-verify'
import VerificationEmail from 'pages/shared/verification-email/verification-email'

const VerificationRoutes = [
  ...welcomeOrganizerRoutes(),
  {
    path: '/user/verify',
    element: <ChangeEmailVerif />,
  },
  { path: '/verification-email', element: <VerificationEmail /> },
]

export default VerificationRoutes
