import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'

import { FileUploader } from './file-uploader'
import { ControlledFileUploaderProps, FileType, TransformedFileType } from './file-uploader.types'

export const ControlledFileUploader = (props: ControlledFileUploaderProps) => {
  const { label, name, containerClass, required, ...otherProps } = props

  const [imageFiles, setImageFiles] = useState<FileType[]>([])

  const { control, setError, clearErrors } = useFormContext()

  const handleRemoveFile = (
    fileToRemove: TransformedFileType,
    onChange: (files: FileType[]) => void
  ) => {
    const updatedFiles = imageFiles.filter((file) => file.id !== fileToRemove.id)
    setImageFiles(updatedFiles)
    onChange(updatedFiles)
    clearErrors()
  }

  return (
    <Form.Group className={containerClass}>
      <div className='d-flex gap-1'>
        {label && <Form.Label>{label}</Form.Label>}
        {required && <span className='text-primary'>*</span>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => {
          if (value && value.length > 0 && imageFiles.length === 0) setImageFiles(value)

          return (
            <>
              <FileUploader
                onFileUpload={(files) => {
                  if (imageFiles.length >= otherProps.maxFile) {
                    setError(name, {
                      type: 'maxFile',
                      message: `Cannot upload more than ${otherProps.maxFile} images`,
                    })
                    return
                  }

                  const transformedFiles = files.map((file) => ({
                    id: `${new Date().getTime()}-${file.image.name}`,
                    name: file.image.name,
                    image: file.image,
                    ogFile: file.ogFile,
                  }))

                  const updatedFiles = [...imageFiles, ...transformedFiles] as FileType[]
                  setImageFiles(updatedFiles)
                  onChange(updatedFiles)
                  clearErrors()
                }}
                isInvalid={invalid}
                value={imageFiles}
                onFileRemove={(file) => handleRemoveFile(file, onChange)}
                {...otherProps}
              />

              {error && (
                <Form.Control.Feedback type='invalid' className='d-block fw-semibold'>
                  {error.message}
                </Form.Control.Feedback>
              )}
            </>
          )
        }}
      />
    </Form.Group>
  )
}
