import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

type BackButtonProps = ButtonProps & {
  containerClass?: string
  icon?: boolean
  title?: string
  onClick?: () => void
  variant?: string
  size?: string
}

export const BackButton = (props: BackButtonProps) => {
  const { containerClass = 'back-button fw-bold', icon, title, variant = '', size, onClick } = props

  return (
    <Button variant={variant} size={size} className={containerClass} onClick={onClick}>
      <div className='d-flex align-items-center text-black'>
        {icon && <i className='icon-chevron-left me-1 font-22 my-n1 py-n1' />}
        {title}
      </div>
    </Button>
  )
}
