import React from 'react'
import { setupReactotron } from 'services/reactotron'

import './i18n'
import { useInitialRootStore } from 'models'
import Routes from 'routes/routes'

import './assets/scss/saas.scss'

import '@splidejs/react-splide/dist/css/themes/splide-default.min.css'

// Set up Reactotron, which is a free desktop app for inspecting and debugging
// React Native apps. Learn more here: https://github.com/infinitered/reactotron
setupReactotron({
  // clear the Reactotron window when the app loads/reloads
  clearOnLoad: true,
  // generally going to be localhost
  host: 'localhost',
  // log the initial restored state from AsyncStorage
  logInitialState: true,
  // log out any snapshots as they happen (this is useful for debugging but slow)
  logSnapshots: false,
})

export function App() {
  const { rehydrated } = useInitialRootStore(() => {
    // This runs after the root store has been initialized and rehydrated.
  })

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  if (!rehydrated) return null

  return <Routes />
}
