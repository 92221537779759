import { TutorialsType } from 'models/tutorials'
import { ApiCore } from './api-core'
import { Payload } from './api-core.types'

export class GeneralApi extends ApiCore {
  async getListTutorials(payload: Payload) {
    return await this.get({
      path: '/tutorials',
      payload,
    })
  }

  async addTutorial(payload: Payload & TutorialsType) {
    return await this.post({
      path: '/tutorials',
      payload,
    })
  }

  async getTutorialsDetails(id: string | number) {
    return await this.get({
      path: `/tutorials/${id}`,
    })
  }

  async updateTutorial(id: string | number, payload: Payload & TutorialsType) {
    return await this.put({
      path: `/tutorials/${id}`,
      payload,
    })
  }

  async deleteTutorial(id: string | number) {
    return await this.delete({
      path: `/tutorials/${id}`,
    })
  }
}

export const generalApi = new GeneralApi()
