import { useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { useStores } from 'models'
import { ApiErrorKind } from 'services/api'
import { useAccountLayout } from 'hooks'
import { useToast } from 'hooks/use-toast.hook'

export default function useEmailVerify() {
  useAccountLayout()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { showToast } = useToast()

  const {
    authStore: { email },
    profileStore: { newEmail, sendVerifyNewEmail, verifyNewEmail },
  } = useStores()

  const userNewEmail = newEmail

  const [error, setError] = useState(null)
  const [errorSend, setErrorSend] = useState(null)

  const tokenResolver = yupResolver(
    yup.object().shape({
      token: yup
        .number()
        .required(t('Please enter your code'))
        .typeError(t('Please enter your code')),
    })
  )

  const backTo = async () => {
    navigate(-1)
  }

  const navigateToProfile = () => {
    navigate('/user/profile', { replace: true })
  }

  const doVerify = async (data: any) => {
    try {
      const submit = await verifyNewEmail({ newEmail: userNewEmail, currentEmail: email, ...data })
      if (submit) {
        navigateToProfile()
        showToast({
          variant: 'success',
          body: t('Update Profile Success'),
        })
      }
    } catch (error: any) {
      if (
        error.kind === ApiErrorKind.UNPROCESSABLE ||
        error.kind === ApiErrorKind.NOT_FOUND ||
        error.kind === ApiErrorKind.BAD_REQUEST
      ) {
        setError(t('The code you entered is incorrect!'))
      }
    }
  }

  const doResendCode = async () => {
    try {
      await sendVerifyNewEmail({ newEmail: userNewEmail, currentEmail: email })
    } catch (error: any) {
      if (error.kind === ApiErrorKind.NOT_FOUND || error.kind === ApiErrorKind.BAD_REQUEST) {
        setErrorSend(t('You already requested, wait for 5 minutes for resend'))
      }
      if (error.kind === ApiErrorKind.UNPROCESSABLE) {
        setErrorSend(t('You already requested, wait for 5 minutes for resend'))
      }
    }
  }
  return { tokenResolver, doVerify, error, backTo, doResendCode, errorSend }
}
