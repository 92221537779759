import { Instance, SnapshotOut, applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree'

import { ApiSuccessResult, Payload, generalApi } from 'services/api'

import { withReset } from './helpers/with-reset'
import { TutorialsModel, TutorialsType } from './tutorials'
import { withSetValue } from './helpers/with-set-value'

export const TutorialsStoreModel = types
  .model('TutorialsStore')
  .props({
    tutorialsParticipantList: types.optional(types.array(TutorialsModel), []),
    tutorialsOrganizerList: types.optional(types.array(TutorialsModel), []),
    selectedTutorial: types.optional(TutorialsModel, {}),
    tabId: types.optional(types.number, 0),
    isEditing: types.optional(types.boolean, false),
  })
  .extend(withSetValue)
  .actions(withReset)
  .views((self) => ({
    get tutorialsParticipant() {
      return getSnapshot(self.tutorialsParticipantList)
    },
    get tutorialsOrganizer() {
      return getSnapshot(self.tutorialsOrganizerList)
    },
    get tutorial() {
      return getSnapshot(self.selectedTutorial)
    },
  }))
  .actions((self) => ({
    getTab: function (id: number) {
      self.tabId = id
    },

    setIsEditing: function (isEditing: boolean) {
      self.isEditing = isEditing
    },

    getTutorialsList: flow(function* (type: 'organizer' | 'participant', payload: Payload) {
      const result: ApiSuccessResult = yield generalApi.getListTutorials(payload)

      if (result.ok) {
        if (type === 'organizer') applySnapshot(self.tutorialsOrganizerList, result.data.data.datas)
        else applySnapshot(self.tutorialsParticipantList, result.data.data.datas)
      }

      return result.ok
    }),

    submitTutorial: flow(function* (id?: string, payload?: Payload & TutorialsType) {
      const { ok }: ApiSuccessResult = id
        ? yield generalApi.updateTutorial(id, payload)
        : yield generalApi.addTutorial(payload)

      if (ok) applySnapshot(self.selectedTutorial, {})
    }),

    getTutorialDetails: flow(function* (id: number | string) {
      const result: ApiSuccessResult = yield generalApi.getTutorialsDetails(id)

      if (result.ok) {
        applySnapshot(self.selectedTutorial, {})
        applySnapshot(self.selectedTutorial, result.data.data)
      }

      return result.ok
    }),

    deleteTutorial: flow(function* (id: string) {
      const result: ApiSuccessResult = yield generalApi.deleteTutorial(id)

      return result.ok
    }),

    resetSelectedTutorial: function () {
      applySnapshot(self.selectedTutorial, {})
    },
  }))

export type TutorialsStore = Instance<typeof TutorialsStoreModel>
export type TutorialsStoreSnapshot = SnapshotOut<typeof TutorialsStoreModel>
